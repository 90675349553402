import styles from "./Header.module.scss"
import Navigation from "./Navigation"
import Logo from "./Logo"
import Link from "next/link"
import Menu from "./Menu"
import { useCallback, useEffect, useState } from "react"
import BurgerBtn from "./BurgerBtn"

const Header = (props) => {
  const { navs } = props
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [animationType, setAnimationType] = useState("soft")
  const [scrollPosition, setScrollPosition] = useState(0)

  const toggleMenu = useCallback(
    (state) => {
      setIsMenuOpen((prevIsMenuOpen) => {
        if (!prevIsMenuOpen) {
          setScrollPosition(document.documentElement.scrollTop)
        }
        return typeof state !== "undefined" ? state : !isMenuOpen
      })
    },
    [isMenuOpen]
  )

  const toggleSoft = useCallback(
    (state) => {
      setAnimationType("soft")
      toggleMenu(state)
    },
    [toggleMenu]
  )

  const toggleHard = useCallback(
    (state) => {
      setAnimationType("hard")
      toggleMenu(state)
    },
    [toggleMenu]
  )

  const toggleMenuFn = (animationType = "hard") => {
    if (animationType === "soft") {
      toggleSoft()
    } else {
      toggleHard()
    }
  }

  useEffect(() => {
    if (typeof document !== "undefined" && isMenuOpen) {
      document.querySelector("body").classList.add("menu-open")
    } else {
      document.querySelector("body").classList.remove("menu-open")
    }

    const nav = document
      .getElementById("main-navigation")
      .getBoundingClientRect()
    const isNavVisible = !nav.x && !nav.width

    if (isNavVisible) {
      if (isMenuOpen) {
        document.documentElement.scrollTo(0, 0)
      } else {
        document.documentElement.scrollTo(0, scrollPosition)
      }
    }
  }, [isMenuOpen])

  useEffect(() => {
    function handleKeyboardEvent(event) {
      const eventKeyCode = event.which || event.keyCode
      if (eventKeyCode === 27) {
        toggleSoft(false)
      }
    }

    if (typeof document !== "undefined" && isMenuOpen) {
      document.addEventListener("keyup", handleKeyboardEvent, false)
    }
    return function cleanup() {
      if (typeof document !== "undefined") {
        document.removeEventListener("keyup", handleKeyboardEvent, false)
      }
    }
  }, [isMenuOpen, toggleSoft])

  return (
    <>
      <header className={styles.header}>
        <div className={styles.inner}>
          <Link href="/">
            <a
              aria-label="disphere Technology GmbH: Zur Startseite"
              className={styles.logo}
            >
              <Logo />
            </a>
          </Link>
          <div className={styles.nav}>
            <Navigation {...props} toggleMenuFn={toggleMenuFn} />
            <BurgerBtn className={styles.burger} onClick={toggleMenuFn} />
          </div>
        </div>
      </header>
      <Menu
        navs={navs}
        isOpen={isMenuOpen}
        animationType={animationType}
        toggleMenuFn={toggleMenuFn}
      />
    </>
  )
}

export default Header
