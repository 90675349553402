import React from "react"
import styles from "./BurgerBtn.module.scss"
import cn from "classnames"
import { Menu } from "feather-icons-react"

const BurgerBtn = ({ onClick, className }) => {
  return (
    <button
      className={cn(styles.btn, className)}
      onClick={onClick}
      aria-label={"Menü"}
    >
      <div className={"bg-berry rounded-full p-3.5"}>
        <Menu className={"text-white w-6 h-6"} />
      </div>
    </button>
  )
}

export default BurgerBtn
