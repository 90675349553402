const Logo = ({
  fill = "currentColor",
  preserveAspectRatio = "xMidYMid",
  width = 201,
  height = 57,
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill={fill}
      preserveAspectRatio={preserveAspectRatio}
      viewBox="0 0 201 57"
    >
      <g id="Ebene_1" clipPath="url(#clip0_8218_8938)">
        <path
          id="Vector"
          d="M45.6818 2.76628C43.1767 5.45977 42.0715 9.17241 41.6294 11.7203C37.2086 8.29885 31.6826 6.26054 25.7144 6.26054C11.4941 6.18774 0 17.5441 0 31.5939C0 45.6437 11.4941 57 25.7144 57C39.9348 57 51.1342 45.8621 51.4289 32.1034V0C51.4289 0 48.1133 0.218391 45.6818 2.76628ZM25.7144 45.1341C18.1254 45.1341 12.0099 39.0192 12.0099 31.5939C12.0099 24.1686 18.199 18.0536 25.7144 18.0536C33.2298 18.0536 39.419 24.0958 39.419 31.5939C39.419 39.092 33.3035 45.1341 25.7144 45.1341Z"
          fill="#FFFFFF"
        />
        <path
          id="Vector_2"
          d="M102.489 36.1801C103.447 36.1801 104.11 36.0345 104.552 35.8161C104.994 35.5977 105.215 35.2337 105.215 34.6514C105.215 34.1418 104.994 33.705 104.552 33.4138C104.11 33.1226 103.3 32.7587 102.268 32.3219C101.605 32.1035 101.016 31.8123 100.5 31.5939C99.9842 31.3027 99.4684 31.0115 99.1 30.6475C98.7316 30.2836 98.4369 29.8468 98.1422 29.41C97.9211 28.9004 97.7738 28.318 97.7738 27.5901C97.7738 26.2069 98.2895 25.115 99.3211 24.3142C100.353 23.5134 101.753 23.0767 103.595 23.0767C104.479 23.0767 105.363 23.1495 106.173 23.2951C106.984 23.4406 107.647 23.5862 108.015 23.8046L107.352 26.6437C106.984 26.4981 106.468 26.2797 105.879 26.1341C105.289 25.9885 104.552 25.9157 103.742 25.9157C103.005 25.9157 102.416 26.0613 101.974 26.2797C101.531 26.4981 101.31 26.8621 101.31 27.3717C101.31 27.5901 101.384 27.8085 101.458 28.0269C101.531 28.2452 101.679 28.3908 101.9 28.5364C102.121 28.682 102.416 28.9004 102.784 29.046C103.152 29.1916 103.595 29.41 104.11 29.5556C104.994 29.8468 105.658 30.2108 106.247 30.5019C106.836 30.7931 107.352 31.1571 107.721 31.5211C108.089 31.8851 108.384 32.3219 108.531 32.8314C108.678 33.341 108.826 33.9234 108.826 34.5786C108.826 36.0345 108.31 37.1265 107.205 37.8544C106.1 38.5824 104.552 38.9464 102.563 38.9464C101.237 38.9464 100.132 38.8008 99.3211 38.5824C98.5106 38.364 97.9211 38.1456 97.5527 38.0728L98.1422 35.161C98.6579 35.3793 99.3211 35.5977 100.058 35.7433C100.795 35.8889 101.531 36.1801 102.489 36.1801Z"
          fill="#FFFFFF"
        />
        <path
          id="Vector_3"
          d="M128.13 31.0843C128.13 32.2491 127.983 33.341 127.688 34.2874C127.393 35.2337 126.951 36.1073 126.362 36.7625C125.772 37.4177 125.109 38 124.225 38.364C123.341 38.728 122.383 38.9464 121.351 38.9464C120.615 38.9464 119.952 38.8736 119.362 38.6552C118.773 38.5096 118.257 38.2912 117.888 38.0728V43.9694H114.352V23.9502C115.089 23.7318 115.973 23.5862 117.004 23.3678C118.036 23.2223 119.215 23.0767 120.394 23.0767C121.572 23.0767 122.678 23.2223 123.636 23.659C124.593 24.023 125.404 24.5326 126.067 25.2606C126.73 25.9885 127.246 26.7893 127.614 27.7357C127.983 28.7548 128.13 29.8468 128.13 31.0843ZM124.52 31.1571C124.52 29.5556 124.151 28.3908 123.414 27.5173C122.678 26.6437 121.572 26.2069 119.952 26.2069C119.657 26.2069 119.288 26.2069 118.92 26.2797C118.552 26.2797 118.183 26.3525 117.815 26.4981V35.2337C118.11 35.4521 118.552 35.6705 119.067 35.8161C119.583 35.9617 120.173 36.1073 120.762 36.1073C122.088 36.1073 123.046 35.6705 123.636 34.797C124.225 33.9234 124.52 32.6131 124.52 31.1571Z"
          fill="#FFFFFF"
        />
        <path
          id="Vector_4"
          d="M152.592 31.1571C152.592 29.8468 152.813 28.6092 153.181 27.6629C153.624 26.6437 154.139 25.8429 154.802 25.115C155.466 24.387 156.202 23.9502 157.087 23.5862C157.971 23.2223 158.855 23.0767 159.739 23.0767C161.876 23.0767 163.497 23.7318 164.676 25.0422C165.854 26.3525 166.444 28.2452 166.444 30.8659V31.5211C166.444 31.7395 166.444 31.9579 166.37 32.1763H156.202C156.276 33.4138 156.718 34.3602 157.529 35.0154C158.339 35.6705 159.444 36.0345 160.918 36.0345C161.802 36.0345 162.539 35.9617 163.276 35.8161C164.012 35.6705 164.528 35.5249 164.97 35.3065L165.412 38.2184C165.191 38.2912 164.97 38.4368 164.602 38.5096C164.234 38.5824 163.865 38.728 163.423 38.8008C162.981 38.8736 162.539 38.9464 162.023 39.0192C161.507 39.092 160.992 39.092 160.476 39.092C159.15 39.092 157.971 38.8736 156.939 38.5096C155.908 38.1456 155.097 37.5632 154.434 36.8353C153.771 36.1073 153.329 35.3065 152.96 34.3602C152.739 33.341 152.592 32.3219 152.592 31.1571ZM162.907 29.5556C162.907 29.046 162.834 28.6092 162.686 28.1724C162.539 27.7357 162.318 27.3717 162.097 27.0077C161.876 26.6437 161.507 26.4253 161.139 26.2797C160.771 26.1341 160.328 25.9885 159.739 25.9885C159.15 25.9885 158.708 26.0613 158.265 26.2797C157.823 26.4981 157.529 26.7893 157.234 27.0805C156.939 27.4445 156.718 27.8085 156.571 28.2452C156.423 28.682 156.276 29.1188 156.276 29.5556H162.907Z"
          fill="#FFFFFF"
        />
        <path
          id="Vector_5"
          d="M181.991 26.6437C181.696 26.5709 181.327 26.4253 180.738 26.3525C180.222 26.2797 179.633 26.2069 178.896 26.2069C178.528 26.2069 178.086 26.2069 177.643 26.3525C177.201 26.4253 176.907 26.4981 176.686 26.5709V38.8008H173.149V24.1686C173.812 23.9502 174.696 23.659 175.728 23.4406C176.759 23.2223 177.864 23.0767 179.191 23.0767C179.412 23.0767 179.706 23.0767 180.001 23.1495C180.296 23.1495 180.591 23.2223 180.959 23.2951C181.254 23.3678 181.548 23.4406 181.843 23.4406C182.138 23.5134 182.359 23.5862 182.58 23.659L181.991 26.6437Z"
          fill="#FFFFFF"
        />
        <path
          id="Vector_6"
          d="M187.148 31.1571C187.148 29.8468 187.369 28.6092 187.738 27.6629C188.106 26.7165 188.695 25.8429 189.359 25.115C190.022 24.387 190.759 23.9502 191.643 23.5862C192.527 23.2223 193.411 23.0767 194.295 23.0767C196.432 23.0767 198.053 23.7318 199.232 25.0422C200.411 26.3525 201 28.2452 201 30.8659V31.5211C201 31.7395 201 31.9579 200.926 32.1763H190.832C190.906 33.4138 191.348 34.3602 192.158 35.0154C192.895 35.6705 194.074 36.0345 195.548 36.0345C196.432 36.0345 197.169 35.9617 197.906 35.8161C198.642 35.6705 199.158 35.5249 199.6 35.3065L200.042 38.2184C199.821 38.2912 199.6 38.4368 199.232 38.5096C198.863 38.5824 198.495 38.728 198.053 38.8008C197.611 38.8736 197.169 38.9464 196.653 39.0192C196.137 39.092 195.621 39.092 195.106 39.092C193.779 39.092 192.601 38.8736 191.569 38.5096C190.537 38.1456 189.727 37.5632 189.064 36.8353C188.401 36.1073 187.959 35.3065 187.59 34.3602C187.296 33.341 187.148 32.3219 187.148 31.1571ZM197.463 29.5556C197.463 29.046 197.39 28.6092 197.242 28.1724C197.095 27.7357 196.874 27.3717 196.653 27.0077C196.358 26.7165 196.064 26.4253 195.695 26.2797C195.327 26.1341 194.885 25.9885 194.295 25.9885C193.706 25.9885 193.264 26.0613 192.822 26.2797C192.38 26.4981 192.085 26.7893 191.79 27.0805C191.495 27.4445 191.274 27.8085 191.127 28.2452C190.98 28.682 190.832 29.1188 190.832 29.5556H197.463Z"
          fill="#FFFFFF"
        />
        <path
          id="Vector_7"
          d="M77.2907 18.3447C76.4066 19.2911 76.4066 21.2566 76.4066 21.2566V24.0229C75.9645 23.8045 75.5224 23.5861 74.933 23.4405C74.3435 23.2949 73.6804 23.1493 72.9436 23.1493C71.8384 23.1493 70.8805 23.3677 69.9964 23.7317C69.1122 24.0957 68.4491 24.678 67.8596 25.3332C67.2702 26.0612 66.8281 26.8619 66.5334 27.8811C66.2387 28.9003 66.0913 29.9194 66.0913 31.157C66.0913 32.3945 66.2387 33.4865 66.6071 34.4328C66.9755 35.3792 67.4912 36.2528 68.1544 36.9079C68.8175 37.5631 69.628 38.1455 70.5858 38.5095C71.5437 38.8734 72.6489 39.0918 73.8277 39.0918C75.0066 39.0918 76.1118 39.019 77.217 38.8006C78.3223 38.6551 79.2064 38.4367 79.8695 38.2183V17.1072C80.0169 17.0344 78.2486 17.3983 77.2907 18.3447ZM76.4802 35.816C76.1855 35.8888 75.8908 35.9616 75.5224 35.9616C75.154 36.0344 74.6382 36.0344 74.0488 36.0344C72.7225 36.0344 71.691 35.5976 70.9542 34.724C70.2174 33.8505 69.849 32.6129 69.849 31.0842C69.849 29.5554 70.1437 28.3907 70.7332 27.5171C71.3226 26.6436 72.2805 26.2068 73.6067 26.2068C74.1962 26.2068 74.7856 26.2796 75.3014 26.498C75.8171 26.7164 76.2592 26.8619 76.5539 27.0803V35.816H76.4802Z"
          fill="#FFFFFF"
        />
        <path
          id="Vector_8"
          d="M89.3005 22.6398C89.8899 22.6398 90.4057 22.4214 90.8478 22.0574C91.2899 21.6934 91.5109 21.1839 91.5109 20.4559C91.5109 19.8007 91.2899 19.2183 90.8478 18.8543C90.4057 18.4904 89.9636 18.272 89.3005 18.272C88.7111 18.272 88.1953 18.4904 87.7532 18.8543C87.3111 19.2183 87.0901 19.8007 87.0901 20.4559C87.0901 21.1111 87.3111 21.6206 87.7532 22.0574C88.1953 22.4942 88.7111 22.6398 89.3005 22.6398Z"
          fill="#FFFFFF"
        />
        <path
          id="Vector_9"
          d="M87.5322 27.8084V38.6551H91.0689V27.8084V23.6589C91.0689 23.6589 89.3006 24.0229 88.3427 24.9693C87.6059 25.77 87.5322 27.4444 87.5322 27.8084Z"
          fill="#FFFFFF"
        />
        <path
          id="Vector_10"
          d="M146.624 27.2989C146.403 26.4254 146.035 25.6974 145.592 25.115C145.077 24.5327 144.487 24.0231 143.603 23.6591C142.793 23.2951 141.761 23.1495 140.509 23.1495C139.993 23.1495 139.477 23.2223 138.961 23.2951C138.445 23.3679 138.003 23.5135 137.561 23.6591V21.7664V17.6169C137.561 17.6169 135.793 17.9809 134.835 18.9273C134.246 19.5825 134.025 20.9656 134.025 21.8392V22.5671V38.7281H137.561V26.571C137.782 26.4982 138.151 26.4254 138.667 26.2798C139.182 26.1342 139.624 26.1342 140.14 26.1342C140.73 26.1342 141.245 26.207 141.687 26.3526C142.13 26.4982 142.424 26.7894 142.645 27.1533C142.866 27.5173 143.087 27.9541 143.161 28.5365C143.235 29.1189 143.308 29.774 143.308 30.6476V38.6553H146.845V30.138C146.919 29.1189 146.845 28.0997 146.624 27.2989Z"
          fill="#FFFFFF"
        />
      </g>
      <defs>
        <clipPath id="clip0_8218_8938">
          <rect width="201" height="57" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default Logo
