import styles from "./Menu.module.scss"
import { FocusScope } from "@react-aria/focus"
import { motion } from "framer-motion"
import Link from "next/link"
import Logo from "../Logo"
import Close from "./Close"
import Primary from "./Primary"
import Secondary from "./Secondary"
import Cta from "./Cta"
import {
  menuAnimation,
  backAnimation,
  frontAnimation,
  headerAnimation,
  listAnimation,
  itemAnimation,
  ctaAnimation,
} from "../../../animations/menu"
import { useState } from "react"

const Menu = ({
  navs = {},
  isOpen = false,
  animationType,
  toggleMenuFn = () => {},
}) => {
  const [isHeaderOpen, setIsHeaderOpen] = useState(false)
  const {
    mainNav = { items: [] },
    menuSocial = { items: [] },
    menuLocations = { items: [] },
  } = navs
  const primaryNav = mainNav.items.filter((navItem) => !navItem.border)
  const ctas = mainNav.items.filter(
    (navItem) => navItem.border && navItem.nav_description
  )
  const secondaryNavs = [menuSocial, menuLocations]

  return (
    <FocusScope contain={isOpen}>
      <motion.div
        className={styles.menu}
        variants={menuAnimation}
        animate={
          isOpen ? "open" : animationType === "soft" ? "closed" : "hardClose"
        }
        initial="closed"
      >
        <motion.div
          className={styles.back}
          variants={backAnimation}
          onClick={() => toggleMenuFn("soft")}
          /*animate={isOpen ? "open" : "closed"}
        initial="closed"*/
        />
        <motion.div
          className={styles.front}
          variants={frontAnimation}
          /*animate={isOpen ? "open" : "closed"}
        initial="closed"*/
        >
          <motion.div
            variants={headerAnimation}
            animate={isOpen ? "open" : "closed"}
            initial="closed"
            className={`${styles.header} ${isHeaderOpen ? styles.open : ""}`}
            onAnimationComplete={(definition) => {
              setIsHeaderOpen(definition === "open")
            }}
          >
            <Link href="/">
              <a
                className={styles.logo}
                onClick={() => toggleMenuFn()}
                aria-label="disphere Technology GmbH: Zur Startseite"
              >
                <Logo fill="white" />
              </a>
            </Link>
            <button
              className={styles.close}
              onClick={() => toggleMenuFn("soft")}
              aria-label="Menü schließen"
            >
              <Close aria-hidden />
            </button>
          </motion.div>
          <div className={styles.inner}>
            <div className={styles.left}>
              <Primary
                navTree={primaryNav}
                isOpen={isOpen}
                toggleMenuFn={toggleMenuFn}
              />
            </div>
            <div className={styles.right}>
              {secondaryNavs.find((nav) => nav.items.length > 0) && (
                <motion.div
                  className={styles.secondaries}
                  variants={listAnimation}
                  animate={isOpen ? "open" : "closed"}
                  initial="closed"
                >
                  {secondaryNavs.map(
                    (nav, i) =>
                      nav.items.length > 0 && (
                        <motion.div
                          key={`secondary-m-${i}`}
                          variants={itemAnimation}
                        >
                          <Secondary
                            key={`secondary-${i}`}
                            isOpen={isOpen}
                            title={nav.title}
                            navTree={nav.items}
                            target={
                              nav.handle === "menu_social" ? "_blank" : "_self"
                            }
                            toggleMenuFn={toggleMenuFn}
                          />
                        </motion.div>
                      )
                  )}
                </motion.div>
              )}
              <motion.div
                className={styles.ctas}
                variants={ctaAnimation}
                animate={isOpen ? "open" : "closed"}
                initial="closed"
              >
                {ctas.map((cta, i) => (
                  <Cta
                    key={`cta-${i}`}
                    isOpen={isOpen}
                    linkText={cta.title}
                    url={cta.url}
                    onClick={toggleMenuFn}
                  />
                ))}
              </motion.div>
            </div>
          </div>
        </motion.div>
      </motion.div>
    </FocusScope>
  )
}

export default Menu
