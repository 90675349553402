import { ArrowRightIcon } from "@radix-ui/react-icons"
import Link from "next/link"
import styles from "./LinkButton.module.scss"

const LinkButton = ({
  data,
  onClick = () => {},
  className = "",
  ...rootProps
}) => {
  let {
    link_url: url,
    link_text: text,
    link_blank: blank,
    show_arrow: showArrow,
  } = data
  blank = blank ? "_blank" : "_self"

  if (data.link_url) {
    return (
      <Link href={url}>
        <a
          className={`button ${className}`}
          target={blank}
          onClick={onClick}
          {...rootProps}
        >
          {text}
          {showArrow && <ArrowRightIcon className={styles.buttonIcon} />}
        </a>
      </Link>
    )
  } else return null
}

export default LinkButton
